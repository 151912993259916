import PropTypes from 'prop-types';

const NoNewCodes = ({ descriptions }) => (
  <div className="ctc-progress__no-new-codes-wrapper">
    <div className="ctc-progress__title">
      {descriptions.CTC_PROGRESS_NO_NEW_CODES || ''}
    </div>
    <div className="ctc-progress__text">
      {descriptions.CTC_PROGRESS_PLAY_MORE || ''}
    </div>
  </div>
);

NoNewCodes.propTypes = {
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default NoNewCodes;
