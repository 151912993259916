import PropTypes from 'prop-types';
import {
  Header, Codes, NoNewCodes, LimitReached, LearnMoreLink,
} from './components';

const CrackTheCodeProgress = ({
  type, codes, progress, descriptions,
}) => {
  const instantWins = codes?.reduce((p, c) => (p + (c.instantWin ? 1 : 0)), 0) || 0;

  return (
    <div className={`ctc-progress ctc-progress--${type}`}>
      <Header
        codes={codes}
        instantWins={instantWins}
        progress={progress}
        descriptions={descriptions}
      />
      { codes.length > 0 ? (
        <Codes
          type={type}
          codes={codes}
          instantWins={instantWins}
          descriptions={descriptions}
        />
      ) : (
        <>
          {
            progress.currentDaily < progress.maxDaily
              ? (
                <NoNewCodes
                  descriptions={descriptions}
                />
              ) : (
                <LimitReached
                  descriptions={descriptions}
                  progress={progress}
                />
              )
            }
          <LearnMoreLink
            type={type}
            descriptions={descriptions}
            linkType="button"
          />
        </>
      ) }
    </div>
  );
};

CrackTheCodeProgress.propTypes = {
  type: PropTypes.string.isRequired,
  codes: PropTypes.instanceOf(Array),
  progress: PropTypes.instanceOf(Object),
  descriptions: PropTypes.instanceOf(Object),
};

CrackTheCodeProgress.defaultProps = {
  codes: [],
  progress: {},
  descriptions: {},
};

export default CrackTheCodeProgress;
