import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'bv-components';
import { isCasino, getMainPath } from '../helpers';

const LearnMoreLink = ({ descriptions, type, linkType }) => {
  const history = useHistory();
  const goToLearnMore = () => {
    if (isCasino(type)) {
      history.replace(getMainPath(type));
    }

    history.push(`/pages/${descriptions.CTC_ZONE_LEARN_MORE_SLUG}`);
  };

  return linkType === 'link' ? (
    <strong
      onClick={goToLearnMore}
      className="ctc-progress__learn-more"
    >
      {descriptions.CTC_ZONE_LEARN_MORE || ''}
    </strong>
  ) : (
    <div className="ctc-progress__button-wrapper">
      <Button
        onClick={goToLearnMore}
        type="button"
        className="ctc-progress__learn-more bvs-button-alternative is-secondary"
        label={descriptions.CTC_ZONE_LEARN_MORE}
      />
    </div>
  );
};

LearnMoreLink.propTypes = {
  type: PropTypes.string.isRequired,
  descriptions: PropTypes.instanceOf(Object).isRequired,
  linkType: PropTypes.string,
};

LearnMoreLink.defaultProps = {
  linkType: 'link',
};

export default LearnMoreLink;
