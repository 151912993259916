import { v as bvVar } from 'bv';

export const isCasino = (type) => type.startsWith('game');

const competitionType = (type) => {
  if (type === 'bet-receipt') return 'standard';
  return type.split('-').pop();
};

export const getMainPath = (type) => (
  bvVar('crackTheCode').paths[competitionType(type)]
);
