import PropTypes from 'prop-types';

const LimitReached = ({ progress, descriptions }) => {
  const timeLeft = progress.nextDailyProgressStart
    ? Date.parse(progress.nextDailyProgressStart) - Date.parse(new Date())
    : null;
  const minutes = timeLeft ? Math.floor((timeLeft / 1000 / 60) % 60) : '-';
  const hours = timeLeft ? Math.floor((timeLeft / (1000 * 60 * 60)) % 24) : '-';

  return (
    <>
      <div className="ctc-progress__title">
        {descriptions.CTC_PROGRESS_REACHED_LIMIT_1 || ''}
      </div>
      <div className="ctc-progress__title">
        {descriptions.CTC_PROGRESS_REACHED_LIMIT_2 || ''}
      </div>
      <div className="ctc-progress__text-small">
        {descriptions.CTC_PROGRESS_LIMIT || ''}
      </div>
      <div className="ctc-progress__text">
        {descriptions.CTC_PROGRESS_TIME_LEFT || ''}
      </div>
      <div className="ctc-progress__time-circle-wrapper">
        <span className="ctc-progress__time-circle">
          {hours}
          <span>{descriptions.CTC_PROGRESS_HOURS || ''}</span>
        </span>
        {' : '}
        <span className="ctc-progress__time-circle">
          {minutes}
          <span>{descriptions.CTC_PROGRESS_MINUTES || ''}</span>
        </span>
      </div>
    </>
  );
};

LimitReached.propTypes = {
  progress: PropTypes.instanceOf(Object).isRequired,
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default LimitReached;
