import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isDesktop } from 'bv';
import { Button } from 'bv-components';
import { hasSportsbook } from 'global-config';
import { isCasino, getMainPath } from '../helpers';

const SeeAllCodes = ({ type, descriptions, instantWins }) => {
  const onClick = () => {
    if (type === 'bet-receipt' && !isDesktop() && hasSportsbook) {
      const hideBetslip = window.SportsbookHelpers?.hideBetslip;
      if (hideBetslip) hideBetslip();
    }
  };

  return (
    <Button
      type="button"
      onClick={onClick}
      to={`${getMainPath(type)}/my-codes`}
      replace={isCasino(type)}
      label={descriptions.CTC_PROGRESS_SEE_ALL_CODES || ''}
      className={classnames('bvs-button-alternative is-secondary ctc-progress__see-all', {
        'ctc-progress__see-all--spaced': instantWins > 0,
      })}
    />
  );
};

SeeAllCodes.propTypes = {
  type: PropTypes.string.isRequired,
  instantWins: PropTypes.number.isRequired,
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default SeeAllCodes;
