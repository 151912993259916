import PropTypes from 'prop-types';
import classnames from 'classnames';
import SeeAllCodes from './see_all_codes';
import LearnMoreLink from './learn_more_link';

const Codes = ({
  type, codes, instantWins, descriptions,
}) => (
  <>
    <div className={classnames('ctc-progress__content_wrapper', { 'ctc-progress__content_wrapper-with-instantwins': instantWins > 0 })}>
      <div className={classnames('ctc-progress__codes', { 'ctc-progress__codes-with-instantwins': instantWins > 0 })}>
        <div className={classnames('ctc-progress__codes-wrapper', { 'with-mask': codes.length > 2 })}>
          {
            codes.map(({ id, codeValue, instantWin }) => (
              <div key={id} className="ctc-progress__code">
                {codeValue.map((group, g) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={g}>
                    {group.map((digit, d) => (
                      <span
                        // eslint-disable-next-line react/no-array-index-key
                        key={d}
                        className={classnames('ctc-progress__digit', {
                          'ctc-progress__digit--transparent': instantWin?.highlighted && !instantWin.highlighted[g][d],
                        })}
                      >
                        {digit}
                      </span>
                    ))}
                  </span>
                ))}
                {instantWin && (
                  <div className="ctc-progress__instant-win-msg">
                    {instantWin.description}
                  </div>
                )}
              </div>
            ))
        }
        </div>
      </div>
      { codes.length > 2 && <div className="ctc-progress__mask" />}
      {
        !codes.some((code) => code.instantWin) && (
          <div className="ctc-progress__text--info">
            {`${descriptions.CTC_PROGRESS_NO_INSTANT_WINS || ''} `}
            <LearnMoreLink
              type={type}
              descriptions={descriptions}
            />
          </div>
        )
      }
    </div>
    <div className="ctc-progress__button-wrapper">
      <SeeAllCodes type={type} descriptions={descriptions} instantWins={instantWins} />
    </div>
  </>
);

Codes.propTypes = {
  type: PropTypes.string.isRequired,
  codes: PropTypes.instanceOf(Array).isRequired,
  instantWins: PropTypes.number.isRequired,
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default Codes;
