import PropTypes from 'prop-types';
import classnames from 'classnames';

const headerStyles = (codes, instantWins, progress) => classnames(
  'ctc-progress__header-wrapper',
  {
    'ctc-progress__header-with-instantwins': instantWins > 0,
    'ctc-progress__header-without-codes': codes.length === 0,
    'ctc-progress__header-limit-reached': progress.currentDaily >= progress.maxDaily,
  });

const Header = ({
  codes, instantWins, progress, descriptions,
}) => (
  <div className={headerStyles(codes, instantWins, progress)}>
    <span className="ctc-progress__ctc-logo" />
    <div className="ctc-progress__title">
      {codes.length > 0 && (
        <span>
          {codes.length === 1
            ? (descriptions.CTC_PROGRESS_ONE_CODE_EARNED || '')
            : (descriptions.CTC_PROGRESS_CODES_EARNED || '')}
        </span>
      )}
    </div>
    {instantWins > 0 && (
      <div className="ctc-progress__title">
        {instantWins === 1
          ? (descriptions.CTC_PROGRESS_ONE_INSTANT_WIN_TITLE || '')
          : (descriptions.CTC_PROGRESS_INSTANT_WIN_TITLE || '')}
      </div>
    )}
  </div>
);

Header.propTypes = {
  codes: PropTypes.instanceOf(Array).isRequired,
  instantWins: PropTypes.number.isRequired,
  progress: PropTypes.instanceOf(Object).isRequired,
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default Header;
